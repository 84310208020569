<template>
  <v-container id="sumarizador" class="pt-0">
    <v-row class="justify-center">
      <v-col cols="auto" xl="3" lg="3" md="6" sm="6" xs="6" align="center">
        <v-layout class="scrolling-wrapper justify-center">
          <div
            :id="`id_${item.options ? item.options : item.id}`"
            style="margin-top: -35px"
            class="px-1"
            v-for="(item, index) in itensList"
            :key="item.title + index"
          >
            <v-img
              v-if="item.badge"
              class="medal-badge"
              :src="`/img/icons/home/${item.badge}`"
            />
            <div
              :style="{
                marginTop: !item.badge ? '45px' : null,
              }"
              @click="$util.link(item.link)"
            >
              <v-img :src="`/img/icons/home/${item.icon}`" class="icons" />
            </div>
            <p
              :class="
                item.title.length > 9
                  ? 'text text-center mb-0'
                  : 'text text-center'
              "
            >
              {{ item.title }}
            </p>
          </div>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "subItensList",
  data() {
    return {
      itensList: [],
    };
  },
  created() {
    this.itensList = this.$auth.getMenuByTitle("Home Health");
  },
};
</script>

<style scoped>
.card {
  width: 56px;
  height: 56px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  margin-left: 5px;
}

.icons {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.text {
  font-size: 14px;
  color: #57556c;
  line-height: 100%;
}

.medal-badge {
  position: relative;
  right: -40px;
  top: 35px;
  text-align: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  z-index: 2;
}

.scrolling-wrapper {
  margin: 0 auto;
  max-width: 360px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera ok */
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
