<template lang="pug">
  item.tour-controlls
    item-section(side)
      .btn(@click="prevStep()" style="z-index: 100002" :class="first ? 'disable' : null")
    v-card(elevation="0" style="background-color: transparent; position: fixed; margin-left: auto;margin-right: auto;margin-top:5px; left: 0;right: 0;text-align: center;")
      item-section
        .flex-center
          .dot(
            style="z-index: 100002; align: top;"
            v-for="(step, s) in steps"
            @click="current = s"
            :key="s"
            :class="s === current ? 'dot-selected' : 'dot-default'")
    item-section(side)
      .btn(@click="nextStep()" style="z-index: 100002;" :class="last ? 'disable' : null")
</template>
<script>
import Item from "../structure/item/item"
import ItemSection from "../structure/item/itemSection"
export default {
  name: 'TourControlls',
  components: {
    Item,
    ItemSection
  },
  props: {
    currentStep: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: v => []
    }
  },
  data () {
    return {
      current: 0
    }
  },
  created(){
    this.current = this.currentStep
  },
  computed: {
    last () {
      return this.current + 1 === this.steps.length
    },
    first () {
      return this.current === 0
    }
  },
  methods: {
    nextStep () {
      const current = this.current
      const max = this.steps.length - 1
      this.current = current < max ? current + 1 : -1
    },
    prevStep () {
      const current = this.current
      const min = 0
      this.current = current > min ? current - 1 : current
    }
  },
  watch: {
    currentStep(newValue){
      this.current = newValue
    },
    current () {
      this.$emit('input', this.current)
    }
  }
}
</script>
<style scoped>
.tour-controlls{
  z-index: 99999;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.dot{
  width: 8px;
  height: 8px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px 1px #000;
  margin: 0 8px;
}
.dot-default{
  background: white;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
  transition: all .5s ease;
}
.dot-selected{
  background: #EA4965;
  width: 30px;
  height: 8px;
  border-radius: 22%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
  transition: all .5s ease;
}
</style>