import $store from '../../store/store'
import $util from '../../common/util'

import {findAllEventsByUserAndEntity} from "../../common/healthcare_service";

const getItemStatusIcon = (status) => {
  if (status == "NF") return {
    order: 1,
    icon: 'fas fa-times',
    color: 'red',
    description: 'Não foi feito',
    ok: false,
    sigla: status
  };
  else if (status == "FA") return {
    order: 3,
    icon: 'fas fa-check',
    color: 'yellow',
    description: 'Feito com atraso',
    ok: true,
    sigla: status

  };
  else if (status == "FE") return {
    order: 4,
    icon: 'fas fa-check',
    color: 'green',
    description: 'Feito em dia',
    ok: true,
    sigla: status
  };
  else if (status == "FU") return {
    order: 2,
    icon: 'fas fa-clock',
    color: 'blue',
    description: 'Agendado',
    ok: false,
    sigla: status
  };
}


const getProtecaoItens = (sheetURI) => {


}

const getListaProtecaoItens = (entity, userID  ) => {
  //Get lista
  if (entity)
    return findAllEventsByUserAndEntity(entity,  userID)
        .then(gsxdatasubitens => {
          // eslint-disable-next-line no-unused-vars
          var gsxarrsubitens = []

          //eslint-disable-next-line
          var lastsubindex = -1;

          //eslint-disable-next-line
          for (const [subindex, subiten] of gsxdatasubitens.data.entries()) {
            if (subiten.medicalEventDetailName) {
              lastsubindex++;
              var finalsubiten = {
                descricao: subiten.medicalEventDetailName,
                homem: {
                  do: (subiten.man),
                  status: (subiten.status? getItemStatusIcon(subiten.status) : {}),
                },
                mulher: {
                  do: (subiten.woman),
                  status: (subiten.status ? getItemStatusIcon(subiten.status) : {}),
                },
                crianca: {
                  do: (subiten.child),
                  status: (subiten.status ? getItemStatusIcon(subiten.status) : {}),
                },
                info: {
                  id: (subiten.id ? subiten.id: false),
                  entity,
                  dtprevista: (subiten.expectedDate ? $util.vueFormatDateToBR(subiten.expectedDate) : false),
                  dtexecutada: (subiten.realizationDate ? $util.vueFormatDateToBR(subiten.realizationDate) : false),
                  local: (subiten.place ? subiten.place : false),
                  orientacoes: (subiten.guidance ? subiten.guidance: false),
                  medico: (subiten.doctorName ? subiten.doctorName : false),
                  tipo : (subiten.medicalEventDetailName ? subiten.medicalEventDetailName: false),
                  tipoSolicitacao: (subiten.solicitationType ? subiten.solicitationType.includes('PROTOCOL') ? 'Protocolo' :
                                                               subiten.solicitationType.includes('FORWARDING') ? 'Encaminhamento' :
                                                               subiten.solicitationType.includes('SOLICITATION') ? 'Solicitação' : false : false),
                  temreceita:  false,
                  originalIndex: lastsubindex
                },
                subitens: []
              }
              gsxarrsubitens.push(finalsubiten);
            } else {
              var addsubitem = {
                tipo: (subiten.solicitationType ? subiten.solicitationType : false),
                qtd: (subiten.quantidade ? subiten.quantidade : false),
                nome: (subiten.name ? subiten.name : false),
                preco: (subiten.price ? subiten.price : false)
              };

              gsxarrsubitens[lastsubindex].subitens.push(addsubitem);
            }
          }
          return gsxarrsubitens;
        })
        .catch(error => {
          $store.commit('showmsg', {
            text: "Erro ao carregar1",
            type: "error"
          });
        });
}


const calculateTotal = (items) => {
  if (items && items.length > 0) {
    var total = items
      .map(item => item.percentual)
      .reduce((prev, next) => prev + (next ? next : 0));

    var ptotal = total / items.filter(item => item.percentual).length;
    return ptotal
  } else return null
}

const getItemAccess = (perfil, item) => {
  if (perfil == "H") return item.homem;
  else if (perfil == "M") return item.mulher;
  else if (perfil == "C") return item.crianca;
}

const protecoesUtil = {
  calculateTotal,
  getItemStatusIcon,
  getProtecaoItens,
  getListaProtecaoItens,
  getItemAccess,
}

export {
  protecoesUtil
}
