/* eslint-disable */
import HttpService from '../HttpService';
export default class UserTourService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security`);
  }

  getCurrentUserTour() {
    return this._httpService.get('/user_tours/getCurrentUserTour');
  }

}
