/* eslint-disable */
import HttpService from '../services/HttpService';

export default class TourService {
  constructor() {
    this._httpService = new HttpService(`security`);
  }

  async current() {
    return await this._httpService.get('/user_tours/getCurrentUserTour');
  }

  async finalize() {
    return await this._httpService.get('/user_tours/finalizeCurrentUserTour');
  }

  async cancel() {
    return await this._httpService.get('/user_tours/cancelCurrentUseTour');
  }
}
