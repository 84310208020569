<template>
  <v-container
    fluid
    id="chartContainer"
    :class="fitContainer ? 'fitContainer' : ''"
  >
    <v-row v-if="false">
      <v-col cols="12">
        <v-btn class="btn btn-great" block rounded large color="primary" @click="()=> {this.connectWebSocket()}">WS CONNECT</v-btn>
      </v-col>
    </v-row>
    <NickBanner />
    <cadastroDialog :dialog.sync="cadastroDialogBool"></cadastroDialog>
    <v-row
      align="start"
      dense
      :class="
        (fitContainer ? 'fitContainer' : '') +
        (inCarousel ? '' : ' pb-1') +
        ' px-1 pt-1'
      "
      :style="inCarousel ? { 'padding-bottom': '22px !important' } : {}"
      v-show="!cadastroDialogBool"
      :key="app.reloadableKey"
    >
      <v-col cols="12">
        <homeHeader :isDisabledMenuMessageria="isDisabledMenuMessageria"/>
      </v-col>

      <v-col
        cols="12"
        class="pa-0"
        id="ucanttouchthis"
      >
        <v-row no-gutters justify="center" id="v-step-1">
          <homeChart />
          <subItensList class="pa-0 ma-0"></subItensList>
        </v-row>
      </v-col>

      <v-col
          class="mt-8 px-0 mx-0"
          cols="12"
          v-if="storiesList"
        >
          <v-row>
            <v-col class="px-2 mx-0" style="padding-bottom: 0" cols="12">
              <v-container class="feed-bg">
                <div id="divisorFeed" class="cell-span"/>
                <v-stories
                  id="score"
                  :bookmark="bookmarkList"
                  :total-new="totalNew"
                  @updateTotalizersFeed="updateTotalizersFeed"
              />
              </v-container>
              <ProgressiveLoader class="container" :disabled="busy" @reachedEnd="getTimeline">
                <v-timeline
                  id="v-step-9"
                  v-if="storiesList"
                  :timeline-items="storiesList"
                  :refresh-timeline="getTimeline"
                  @updateTotalizersFeed="updateTotalizersFeed"
              />
            </ProgressiveLoader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <TourHome :ready="loadTour"/>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { protecoesUtil } from "./protecoesUtil";
import cadastroDialog from "./../app/cadastroDialog.vue";
import protecoesListaItens from "./protecoesListaItens.vue";
import slider from "./slider";
import slidersumatizador from "./slidersumarizador";
import slideritens from "./slideritens";
import { Touch } from "vuetify/lib/directives";
import { mapState, mapMutations } from "vuex";

import stories from "../../components/protecoes/stories";
import timeline from "../../components/protecoes/timeline";
import ProgressiveLoader from "../../components/progressive-loader/index";
import homeHeader from "./homeHeader";
import subItensList from "./subItensList";
import homeChart from "./homeChart";

import { parseISO, formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";

import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import MessageService from "@/services/socialnetwork/MessageService";
import PushNotification from "@/services/socialnetwork/PushNotification";
import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";
import WebSocketService from "./../../services/euprotegido/WebSocketService";

import TourHome from '../../components/tour/TourHome'

import NickBanner from "../../components/nick-banner/NickBanner";
import UserTourService from "../../services/security/UserTour";
import moment from 'moment-timezone'
import * as Sentry from "@sentry/vue";

export default {
  name: 'Home',
  components: {
    "slide-sumarizador": slidersumatizador,
    "slide-item": slideritens,
    protecoesListaItens,
    cadastroDialog,
    slider,
    "v-stories": stories,
    "v-timeline": timeline,
    ProgressiveLoader,
    homeHeader,
    subItensList,
    TourHome,
    NickBanner,
    homeChart
  },
  directives: {
    Touch,
  },
  props: {
    inCarousel: {
      type: Boolean,
      default: false,
    },
    showCadastroDialog: {
      type: Boolean,
      default: false,
    },
    fitContainer: {
      type: Boolean,
      default: true,
    },
    stepType: {
      type: String,
      default: "steps",
    },
  },
  beforeMount() {
  this._userTourService = new UserTourService();
    let url = window.location.href;
  this._userTourService.getCurrentUserTour()
    .then((response) =>{
      if(response.data && !url.includes('?showTour')){
        if(!response.data.visualizedTour){
          this.$util['showTour']()
          this.$util.link('home-tour', null, null);
        }
      }
    })
  },
  async mounted() {
    this.bookmarkView();
    this.loadTour = true;
    // this.controlTour();

  },
  watch: {
    "menu.showtour"() {
      this.controlTour();
    },
  },
  data() {
    return {
      totalNew: 0,
      loadTour: false,
      busy: true,
      totalPages: 1,
      page: 0,
      cadastroDialog: null,
      storiesList: [],
      timelineList: [],
      bookmarkList:[],
      isDisabledMenuMessageria: true,
    };
  },
  computed: {
    ...mapState(["app", "menu"]),
    cadastroDialogBool: {
      get: function () {
        if (this.$auth.userIsSF()) return false;

        return this.cadastroDialog != null
          ? this.cadastroDialog
          : this.showCadastroDialog;
      },
      set: function (value) {
        this.cadastroDialog = value;
        if (value == false) this.initialize();
      },
    },
  },
  async created() {
    // this.$router.push({name: 'root'})
    this._socialNetworkService = new SocialNetworkService();
    this._euProtegidoService = new EuProtegidoService();
    this._messageService = new MessageService();
    this._pushNotification = new PushNotification();

    if(this.$route.params && this.$route.params.messageException) {
      this.showmsg({text: this.$route.params.messageException, type: "error"});
    }

    this.verifyIsDisabledMenuMessageria();
    this.getTimeline();
    this.saveTokenPushNotification();
  },
  methods: {
    ...mapMutations([
      "refreshReloadableKey",
      "toggleShowTour",
      "showmsg",
      "loading",
    ]),
    saveTokenPushNotification(){
      let tokenFirebase = localStorage.getItem('PUSH_NOTIFICATION_TOKEN');

      if(!tokenFirebase){
        return;
      }

      this._pushNotification.SavePushNotification({tokenFirebase})
          .then(() => {})
    },
    async verifyIsDisabledMenuMessageria(){
      this._messageService.IsDisabledMenuMessageria()
        .then(response => {
          this._websocket = new WebSocketService();
          if(response.data){
            this._websocket.Disconnect();
          }else{
            this.connectWebSocket();
          }
          this.isDisabledMenuMessageria = response.data
        })
        .catch(()=>{
          this.isDisabledMenuMessageria = false;
          this.connectWebSocket();
        })
    },
    updateTotalizersFeed(){
      this.totalNew--;
    },
    connectWebSocket(){
      this._websocket.Connect();
    },

    getTimeline() {
      if (this.page < this.totalPages) {
        let getUser = this.$auth.user();
        this.loading(true);
        let stories = [];
        this.busy = true;
        this._socialNetworkService.countFeedsByEntity(this.$auth.getBeneficiarySituation()).then((_res) => {
        })
        .catch(error => {
          Sentry.captureException(error)
        })
        this._socialNetworkService
          .FindFeedItemUser(getUser.id, this.page, this.$auth.getBeneficiarySituation())
          .then((_res) => {
            if (_res.data && _res.data.totalElements > 0) {
              this.totalPages = _res.data.totalPages;
              _res.data.content.forEach(function (elem) {
                let store = {
                  id: elem.id,
                  origemthumb: elem.thumbnail,
                  origem: elem.name ? elem.name : "---",
                  titulo: elem.description ? elem.description : "---",
                  medias: [],
                  descricao: elem.contents,
                  typemidia: "",
                  linkimage: null,
                  linkvideo: null,
                  lida: elem.view && elem.view > 0,
                  lidaOrigem: elem.view && elem.view > 0,
                  exibirtudo: false,
                  curtidas: elem.like_count ? elem.like_count : 0,
                  like: elem.like && elem.like > 0,
                  likeClick: 0,
                  likeOrigem: elem.like && elem.like > 0,
                  likeId: elem.like && elem.like > 0 ? elem.like : 0,
                  bookmark: elem.bookmark && elem.bookmark > 0,
                  bookmarkOrigem: elem.bookmark && elem.bookmark > 0,
                  bookmarkId:
                    elem.bookmark && elem.bookmark > 0 ? elem.bookmark : 0,
                };

                //Corrigi possível erro no vínculo do back
                if (store.curtidas == 0 && store.likeId > 0) {
                  store.curtidas = 1;
                }

                try {
                  store.datahora = formatDistance(new Date(moment(elem.publish_date).format()),
                      new Date(moment.tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss')), {
                        addSuffix: true,
                        locale: pt,
                      });
                } catch (iosEx) {
                  store.datahora = formatDistance(new Date(elem.publish_date),
                      new Date(), {
                        addSuffix: true,
                        locale: pt,
                      });
                }

                stories.push(store);
              });
            }
          })
          .catch(error => {
            Sentry.captureException(error)
            this.showmsg({
              text: "Erro ao carregar timeline!",
              type: "error",
            })
          })
          .finally(() => {
            if (stories && stories.length > 0) {
              stories.forEach((feed) => {
                this.storiesList.push(feed);
              });
              this.storiesList.forEach((feed) => {
                if (feed.typemidia == "") {
                  this.getMidia(feed);
                }
              });
              this.busy = false;
              this.page++;
            }
            this.loading(false);
          });
      } else {
        this.busy = true;
      }
    },
    getMidia(feed) {
      this._socialNetworkService
        .GetMidiaFeed(feed.id)
        .then((_res) => {
          // this.totalNew = _res.data.length
          if (_res.data && _res.data.length > 0) {
            feed.medias = _res.data
          }
        })
        .catch((error) => {
          Sentry.captureException(error)
        })
        .finally(() => {});
    },
    bookmarkView(){
      this._socialNetworkService.FindFeedItemUserByParams(this.userId, 0, "POST", true, this.$auth.getBeneficiarySituation())
        .then(response => {
          this.totalNew = response.data.totalElements
        })
        .catch((error) => {
          Sentry.captureException(error)
        })
    },
    getTipo(item) {
      switch (item.descricao) {
        case "Minha Saúde":
          return "item-saude";
          break;
        default:
          return "";
      }
    },
    goToLinkPandemia() {
      window.location.href = "https://protejase.covid19.firecodeit.net/";
    },
    swipe(direction) {
      if (direction == "left") {
        if (this.$route.params.activeTab != 1)
          this.$util.link("protecoes", { activeTab: 1 });
      }
    },
    conectarEmpresa() {
      this.cadastroDialogBool = true;
    },
    getStepTour() {
      if (this.stepType) {
        if (this.stepType == "step") {
          return this.steps;
        } else if (this.stepType == "step1") {
          return this.steps1;
        }
      } else return this.steps;
    },
  },
};
</script>

<style>

#chart {
  width: 75%;
  height: auto;
}

.highcharts-subtitle {
  text-align: center !important;
}
.agendamento-status {
  border: 1px solid #333 !important;
  border-radius: 5px;
}

.customcolparent {
  display: table;
  margin-top: 2px;
  margin-bottom: 2px;
}

.customcol {
  display: table-cell;
  vertical-align: middle;
  border-radius: 5px;
  height: 110px;
  margin-right: 10px;
  padding: 5px 10px;
  max-width: 250px;
  min-width: 50px;
  width: auto;
}

.swiper-slide {
  width: auto !important;
  margin-right: 10px;
}

.v-chip__content {
  align-items: center;
}

.v-input__slot {
  box-shadow: none !important;
}

.v-input__slot .transparent {
  box-shadow: none !important;
}
.v-chip.v-size--small {
  padding-right: 100%;
}
.v-input__append-inner {
  padding: initial;
  /* padding-left: -2px !important; */
}
.v-input__icon {
  justify-content: initial !important;
}

.v-input__control .v-text-field__details {
  display: none;
}

.feed-bg {
  background: white !important;
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.15);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.cell-span {
  background: #DBD9DE;
  border-radius: 5px;
  width: 50px;
  height: 5px;
  left: 50%;
  position: relative;
  margin-left: -25px;
}
</style>
