<template>
  <swiper :options="swiperOption" id="swiper-sumarizador-6">
    <template v-for="(item, index) in sumarizadores">
      <swiper-slide
        :key="'swiper-slidee-' + index"
        v-if="!item.subitens || allSaldoCalculated"
        id="card-box-slide"
        :style="{
          border:
            '5px solid ' + (item.color ? item.color : 'white') + ' !important',
        }"
      >
        <div @click="onClickItem(item)">
          <span
            v-html="$util.breakSpace(item.descricao)"
            style="font-size: 11px"
            class="thinbr white--text"
          />
          <br />

          <template
            v-if="item.listSheet && !item.forcepercentual"
            @click="onClickItem(item)"
          >
            <span
              v-if="item.saldo"
              :style="{ color: '#FFF' }"
              class="body-2"
            >
              <template v-if="item.saldo.todo == 0">
                <v-icon
                  class="pb-1"
                  :style="{ color: '#FFF', 'font-size': '22px' }"
                  >fas fa-medal</v-icon
                >
              </template>
              <template v-else>
                <span class>
                  {{ item.saldo.todo }}/<span class="font-weight-bold">{{
                    item.saldo.total
                  }}</span>
                </span>
              </template>
            </span>
          </template>

          <template v-else-if="item.percentual" @click="onClickItem(item)">
            <span :style="{ color: item.color }" class="subtitle-1">
              <span class="font-weight-bold">{{ item.percentual }}</span
              >%
            </span>
          </template>

          <template v-else @click="onClickItem(item)">
            <v-icon color="black">fas fa-ellipsis-h</v-icon>
          </template>
        </div>
      </swiper-slide>
    </template>
  </swiper>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    sumarizadores: {
      type: Array,
      default: () => [],
    },
    allSaldoCalculated: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
      },
      carouselIndex: 0,
    };
  },
  methods: {
    onClickItem(itemm) {
      this.$emit("evento", itemm);
    },
  },
};
</script>

<style>
.customcolparent {
  display: table;
  margin-top: 2px;
  margin-bottom: 2px;
}

.customcol {
  display: table-cell;
  vertical-align: middle;
  border-radius: 5px;
  height: 110px;
  margin-right: 10px;
  padding: 5px 10px;
  max-width: 250px;
  min-width: 50px;
  width: auto;
}
.swiper-slide {
  width: auto !important;
  margin-right: 10px;
}

#card-box-slide {
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  width: 90px !important;
  height: 70px;
  padding-top: 20px;
  margin-left: 14px;
}

#swiper-sumarizador .swiper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center !important;
}
</style>
