<template>
  <swiper :options="swiperOption">
    <template v-for="(item, index) in itens">
      <swiper-slide
        :key="'swiper-slidee-' + index"
        id="card-box-slide-item"
      >
        <v-card hover class="fill-height tertiary elevation-3">
          <span class="title text-center" style="display: block !important"
            >{{item.titulo}}</span
          >
          <span
            class="sub-title text-center"
            style="display: block !important; font-size: 12px; font-weith: bold"
            >{{item.descricao}}</span
          >
        </v-card>
      </swiper-slide>
    </template>
  </swiper>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    itens: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
      },
      carouselIndex: 0,
    };
  },
  methods: {
    onClickItem(itemm) {
      this.$emit("evento", itemm);
    },
  },
};
</script>

<style>
/* .swiper-slide {
  width: auto !important;
  margin-right: 15px;
} */
/* #card-box-slide {
  border-radius: 10%;
  cursor: pointer;
  width: 100px !important;
  height: 74px;
  text-align: center;
} */

.swiper-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.swiper-container {
  overflow: initial;
}

.card-box-slide-item {
  width: 30%;
}
</style>
