/* eslint-disable */
import HttpService from '../HttpService';
export default class SocialNetworkService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`socialnetwork`);
  }

  SavePushNotification(data) {
    return this._httpService.post("/push_notification", data);
  }
}

