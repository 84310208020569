import axios from 'axios';
import $auth from "./auth";

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;


const postEvent = (entity, data) => {
    const url = `${VUE_APP_API_URL}/` + entity;

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data,
    });
};

const putEvent = (entity, data) => {
    const url = `${VUE_APP_API_URL}/` + entity + '/' + data.id;

    return axios({
        url: url,
        method: 'put',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data,
    });
};

const findEvent = (entity, id) => {
    const url = `${VUE_APP_API_URL}/` + entity + '/' + id;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const findAllEvents = (entity) => {
    const url = `${VUE_APP_API_URL}/` + entity;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const deleteEvent = (entity, id) => {
    const url = `${VUE_APP_API_URL}/` + entity + '/' + id;

    return axios({
        url: url,
        method: 'delete',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const findAllEventsByUserAndEntity = (entity, userID) => {
    const url = `${VUE_APP_API_URL}/` + entity + '/findAllByUser/' + userID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const findAllEventsDetail = (entity) => {
    const url = `${VUE_APP_API_URL}/` + entity + '_detail';

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const getEntityByEventType = (eventType) => {
    return eventType.includes('Consulta') ? 'consultation' :
           eventType.includes('Exame') ? 'exam' :
           eventType.includes('Vacina') ? 'vaccine' : '';
}

const getSolicitationTypeByTipoSolicitacao = (tipoSolicitacao) => {
    return tipoSolicitacao.includes('Protocolo') ? 'PROTOCOL' :
           tipoSolicitacao.includes('Encaminhamento') ? 'FORWARDING' :
           tipoSolicitacao.includes('Solicitação') ? 'SOLICITATION' : null
}

const findAllEventsDetailNames = (entity) => {
    return findAllEventsDetail(entity).then(result => {
        const names = result.data.map(function(item) {
            return item['name'];
        });
        return names.sort();
    });
}

const findAllMedicalEventsAttachment = (medicalEventID) => {
    const url = `${VUE_APP_API_URL}/medical_event_attachment/findAllByMedicalEventID/` + medicalEventID ;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};
export {
    postEvent,
    putEvent,
    findEvent,
    findAllEvents,
    deleteEvent,
    findAllEventsByUserAndEntity,
    findAllEventsDetail,
    getEntityByEventType,
    findAllEventsDetailNames,
    getSolicitationTypeByTipoSolicitacao,
    findAllMedicalEventsAttachment
};
