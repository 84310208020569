<template>
  <v-row class="pl-2 pt-8 mb-5">
    <v-col cols="8" class="pl-5 pb-0">
      <p class="mb-0 pb-2 generic-text">Olá,</p>
      <v-row class="pl-2">
        <p style="font-size: 30px" class="mb-0 main-title text-truncate">
          {{ $auth.user().name.split(' ')[0] }}{{ $auth.user().name.split(' ')[0].length > 13 ? '...' : '' }}
        </p>
      </v-row>
    </v-col>
    <v-col id="MenuNick" cols="3" class="px-0 mx-0">
      <v-row align="center" justify="end">
        <v-col class="pl-0" cols="6" align="end">
          <v-icon @click="$util.link('menu-nick')" size="46" color="primary" class="pb-2 pl-2">fas fa-bars</v-icon>
<!--          <img
              v-bind="attrs"
              v-on="on"
              @click="$util.link('menu-nick')"
              src="/img/nick/nick-smile-icon.svg"
              style="width: 56px; height: 56px;"
          >-->
        </v-col>
      </v-row>
    </v-col>
<v-col cols="8" class="pl-8 pb-0">
      <v-row>
        <p v-if="$auth.user().hasCompanyConnection == true && showCompanyName" style="width: 70vw; font-size: 17px;position: relative; top:-20px; left: -2px" class="mb-0 pt-2 main-text">{{ hasConnectionText }}</p>
        <v-col v-if="!showCompanyName" class="ma-0 pa-0" cols="4"><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
      </v-row>
    </v-col>
    <v-col style="margin-top: -10px" id="chatMessage" cols="3" class="px-0 mx-0">
      <v-row align="center" justify="end">
        <v-col v-if="!isDisabledMenuMessageria" class="pl-0 py-0" cols="6">
          <v-badge :content="getCountUnreadMessages()" :value="getCountUnreadMessages()" color="red"  overlap >
            <img
              @click="$util.link('message')"
              src="/img/icons/chat/chat.svg"
              style="width: 56px; height: 56px;"
            >
          </v-badge>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MessageService from "../../services/socialnetwork/MessageService";
import EuProtegidoService from "../../services/euprotegido/EuProtegidoService";
export default {
  name: "protecaoHeader",
  data: () => ({
    configHomeHeader: null,
    benefitOrderNames: [],
    indexOfBenefit: 0,
    showCompanyName: false,
  }),
  props: {
    isDisabledMenuMessageria: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  async created() {
    this._messageService = new MessageService();
    this._euProtegidoService = new EuProtegidoService();
    await this.loadCompanyBenefitOrder();
    this.loadCompanyNameConfiguration();
    this.loadCountUnreadMessages();
  },
  computed: {
    hasConnectionText(){
      if(this.configHomeHeader && this.configHomeHeader === 'Grupo Financeiro'){
        if(this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length > 0){
          if(this.$auth.user().financialGroupName){
            return `${this.$auth.user().financialGroupName}`
          } else {
            return `${this.$auth.user().beneficiaries[this.indexOfBenefit].contractBusinessName}`
          }
        } else if (this.$auth.user().dependents && this.$auth.user().dependents.length > 0){
          if(this.$auth.user().financialGroupName){
            return `${this.$auth.user().financialGroupName}`
          } else {
            return `${this.$auth.user().dependents[this.indexOfBenefit].contractBusinessName}`
          }

        }
        return ''
      }else if(this.configHomeHeader && this.configHomeHeader === 'Contrato'){
        if(this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length > 0){
          return `${this.$auth.user().beneficiaries[this.indexOfBenefit].contractBusinessName}`
        } else if (this.$auth.user().dependents && this.$auth.user().dependents.length > 0){
          return `${this.$auth.user().dependents[this.indexOfBenefit].contractBusinessName}`
        }
        return ''
      }else {
        if(this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length > 0){
          if(this.$auth.user().beneficiaries[this.indexOfBenefit].subContractHomeNameEuProtegido){
            return `${this.$auth.user().beneficiaries[this.indexOfBenefit].subContractHomeNameEuProtegido}`
          } else {
            return `${this.$auth.user().beneficiaries[this.indexOfBenefit].subContractBusinessName}`
          }
        } else if (this.$auth.user().dependents && this.$auth.user().dependents.length > 0){
          if(this.$auth.user().dependents[0].subContractHomeNameEuProtegido){
            return `${this.$auth.user().dependents[this.indexOfBenefit].subContractHomeNameEuProtegido}`
          } else {
            return `${this.$auth.user().dependents[this.indexOfBenefit].subContractBusinessName}`
          }
        }
        return ''
      }
    }
  },
  methods: {
    ...mapMutations(['setCountUnreadMessages']),
    ...mapGetters(["getCountUnreadMessages"]),
    getIndexOfBeneficiariesByBenefitOrderNames() {
      let indexOf = 0;
      let beneficiariesPlans = this.$auth.user() && this.$auth.user().beneficiaries.length ? this.$auth.user().beneficiaries : this.$auth.user().dependents;
      if(this.benefitOrderNames && this.benefitOrderNames.length && beneficiariesPlans && beneficiariesPlans.length) {
        //conventional loop to use break
        for(const benefit of this.benefitOrderNames) {
          const beneficiaryPlanFound = beneficiariesPlans.find(beneficiaryPlan => beneficiaryPlan.benefitName === benefit);
          if(beneficiaryPlanFound) {
            indexOf = beneficiariesPlans.indexOf(beneficiaryPlanFound);
            break;
          }
        }
      }
      return indexOf;
    },
    loadCountUnreadMessages(){
      this._messageService.CountUnreadMessages()
        .then((response) =>{
          this.setCountUnreadMessages(response.data);
        })
        .catch(() => {
          this.setCountUnreadMessages(null);
        })
    },
    async loadCompanyBenefitOrder() {
      await this._euProtegidoService.FindCompanyBenefitOrder()
          .then((response) => {
            if(response && response.data) {
              this.benefitOrderNames = response.data;
              this.indexOfBenefit = this.getIndexOfBeneficiariesByBenefitOrderNames();
            }
          })
          .finally(() => {
            this.showCompanyName = true;
          });
    },
    loadCompanyNameConfiguration(){
      this._euProtegidoService.FindCompanyNameConfigurationByFinancialGroup()
        .then(response => {
          if(response && response.data){
            this.configHomeHeader = response.data;
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
