<template lang="pug">
  tour(v-if="tourSteps.length && showTour"
    @openAnotherPage="openPage"
    @update="updateCurrentStep"
    v-model="showTour"
    :steps="tourSteps")
</template>
<script>
import TourService from "../../common/TourService"
import Tour from './Tour'
import TourControlls from "./TourControlls";

export default {
  name: 'TourHome',
  components: {
    Tour,
    TourControlls
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tourSteps: [],
      showTour: false,
      tourSrv: null
    }
  },
  async mounted () {
    if (this.ready) {
      this.tourSrv = new TourService()
      await this.loadTour()
    }
  },
  watch: {
    async ready () {
      this.tourSrv = new TourService()
      await this.loadTour()
    },
    async showTourStorage () {
      await this.loadTour()
    },
    showTour () {
      localStorage.setItem('showTour', this.showTour)
    }
  },
  computed: {
    showTourStorage () {
      return localStorage.getItem('showTour') === "true"
    }
  },
  methods: {
    openPage (step) {
    },
    async updateCurrentStep (step) {
      try {
        // if last step, update accepted status
        if ((step + 1) === this.tourSteps.length) {
          await this.tourSrv.finalize()
          localStorage.removeItem('showTour')
        }
      } catch (error) {
        return error
      }
    },
    async loadTour () {
      try {
        const { data: { templateBody, accept } } = await this.tourSrv.current()
        this.tourSteps = JSON.parse(templateBody)
        if (!accept) {
          this.showTour = true
        }
        this.showTour = this.showTourStorage
      } catch (err) {
        return err
      }
    }
  }
}
</script>