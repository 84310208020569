<template>
  <v-row dense :style="{ 'padding-bottom': '58px' }">
    <v-btn
      v-if="btnAdd"
      id="addItem"
      color="primary"
      fab
      fixed
      bottom
      right
      @click="newItem()"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>

    <template v-for="(agrupador, aindex) in agrupadores">
      <template v-if="!onlyMonths || (onlyMonths && agrupador.month)">
        <v-col
          cols="12"
          :key="'agrupador_' + '_' + aindex"
          class="text-center"
          :id="agrupador.id"
        >
          <v-card class="subtitle-1 primary2 white--text elevation-1">
            {{ agrupador.descricao }}
          </v-card>
        </v-col>
        <v-col cols="12" :key="'carteira_' + '_' + aindex">
          <v-row no-gutters>
            <v-col cols="12" class="body-2">
              <v-list
                :two-line="true"
                color="transparent"
                class="custom"
                v-if="selectedItemFilteredAndSorted(agrupador).length > 0"
              >
                <template
                  v-for="(subitem, sindex) in selectedItemFilteredAndSorted(
                    agrupador
                  )"
                >
                  <v-list-item
                    :key="'list-item' + sindex"
                    @click="protecaoDialog(subitem)"
                    :id="'list-item-id-' + sindex"
                  >
                    <v-list-item-avatar>
                      <v-icon small :color="subitem.status.color"
                        >far fa-circle</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="white--text">{{
                        subitem.descricao
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="caption white--text"
                        v-if="subitem.info.tipo"
                        >{{ subitem.info.tipo }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="white--text"
                        v-if="subitem.info.medico"
                      >
                        {{ subitem.info.medico }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="white--text"
                        v-if="subitem.info.local"
                      >
                        {{ subitem.info.local }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="white--text caption mr-2">
                      <v-list-item-action-text
                        class="white--text"
                        v-if="defaultCarteira == allItensDescription"
                      >
                        <span class="white--text">{{
                          subitem.carteira.name
                        }}</span>
                      </v-list-item-action-text>

                      <v-list-item-action-text v-if="subitem.status.ok">
                        <template v-if="subitem.info.dtexecutada">
                          <span class="white--text">Realizado em</span>
                          <br />
                          <span class="white--text font-weight-bold">{{
                            subitem.info.dtexecutada
                          }}</span>
                        </template>
                      </v-list-item-action-text>

                      <v-list-item-action-text v-else>
                        <template v-if="subitem.info.dtprevista">
                          <template v-if="subitem.status.sigla == 'FU'"
                            ><span class="white--text"
                              >Agendado para</span
                            ></template
                          >
                          <template v-else
                            ><span class="white--text"
                              >Previsto para</span
                            ></template
                          >
                          <br />
                          <span class="white--text font-weight-bold">{{
                            subitem.info.dtprevista
                          }}</span>
                        </template>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="
                      selectedItemFilteredAndSorted(agrupador).length !=
                      sindex + 1
                    "
                    :key="'divider' + '_' + sindex"
                  ></v-divider>
                </template>
              </v-list>
              <div v-else class="white--text text-center">Não possui itens</div>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { protecoesUtil } from "./protecoesUtil";
import { mapState, mapMutations } from "vuex";
import moment from 'moment';

export default {
  data() {
    return {
      agrupadores: [
        {
          descricao: "Atrasados e Futuros",
          status: ["NF", "FU"],
          month: false,
          crescente: true,
          id: "agrupador_atraso",
        },
      ],
    };
  },
  methods: {
    newItem() {
      this.$util.link("new-protecoes-evento", {
        title: "Inserir " + this.$route.params.title,
        tipo: "Próprio",
      });
    },
    protecaoDialog(subitem) {
      this.$util.link("detail-subiten-protecoes", {
        title: this.$route.params.title,
        id: this.listID,
        perfil: subitem.carteira.perfil,
        index: subitem.info.originalIndex,
        tipo: subitem.info.tipo,
        nome: subitem.carteira.name,
        item: subitem,
      });
    },
    selectedItemFilteredAndSorted(agrupador) {

      var nsubitems = [];
      for (const [sindex, subitem] of this.subitems.entries()) {
        for (const [cindex, carteira] of this.carteiras.entries()) {
          if (
            carteira.tipo &&
            (this.defaultCarteira == this.allItensDescription ||
              this.defaultCarteira == carteira.name)
          ) {
            var myaccess = protecoesUtil.getItemAccess(
              carteira.perfil,
              subitem
            );

            if (myaccess.do) {
              nsubitems.push({
                descricao: subitem.descricao,
                info: subitem.info,
                status: myaccess.status,
                carteira: carteira,
              });
            }
          }
        }
      }

      var filtered = nsubitems.filter(function (item) {
        var isvalid = false;

        //
        if (agrupador.status && agrupador.status.includes(item.status.sigla)) {
          isvalid = true;
        }

        if (isvalid && agrupador.data) {
          if (
            item.info.dtexecutada &&
            item.info.dtexecutada.includes(agrupador.data)
          ) {
            isvalid = true;
          } else if (
            item.info.dtprevista &&
            item.info.dtprevista.includes(agrupador.data)
          ) {
            isvalid = true;
          } else isvalid = false;
        }

        return isvalid;
        //
      });

      const funcParseData = (obj) => {
        var val = obj.info.dtprevista
          ? obj.info.dtprevista
          : obj.info.dtexecutada;
        var val2 = this.$util.vueFormatDateFromBR(val) + "T12:00:00.000+0000";
        return new Date(val2);
      };

      const sorted = filtered.slice().sort((a, b) => {
        var dta = funcParseData(a);
        var dtb = funcParseData(b);

        if (dta != dtb) {
          return agrupador.crescente ? dta - dtb : dtb - dta;
        } else if (a.status.order == b.status.order) {
          return a.descricao.localeCompare(b.descricao);
        } else {
          return a.status.order - b.status.order;
        }
      });

      return sorted;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getAgrupadorDescricao(subtractMonths){
      moment.locale('pt-br');
      let descricao = moment().subtract(subtractMonths, 'months').format('MMMM YYYY');
      return this.capitalizeFirstLetter(descricao);
    },
    getAgrupadorData(subtractMonths){
      moment.locale('pt-br');
      return moment().subtract(subtractMonths, 'months').format('/MM/YYYY');
    },
    /*adicionarAgrupadorDemaisDatas(){
      const agrupador = {
        descricao: 'Demais Datas',
        status: ["FE", "FA"],
        data: "/",
        month: true,
        id: "agrupador_others",
      }
      this.agrupadores.push(agrupador);
    },*/
    carregarAgrupadores(){
      var indice;
      const totalMeses =  6
      for (indice = 0; indice < totalMeses; indice++) {
        const agrupador = {
          descricao: this.getAgrupadorDescricao(indice),
          status: ["FE", "FA"],
          data: this.getAgrupadorData(indice),
          month: true,
          id: "agrupador_month_" + indice,
        }
        this.agrupadores.push(agrupador);
      }
      //this.adicionarAgrupadorDemaisDatas();
    },
  },
  mounted() {
    this.carregarAgrupadores();
  },
  computed: {
    protecoesUtilComputed() {
      return protecoesUtil;
    },
  },
  props: {
    listID: {
      type: String,
      default: "",
    },
    entity: {
      type: String,
      default: "",
    },
    subitems: {
      type: Array,
      required: true,
    },
    carteiras: {
      type: Array,
      required: true,
    },
    defaultCarteira: {
      type: String,
    },
    allItensDescription: {
      type: String,
    },
    btnAdd: {
      type: Boolean,
      default: true,
    },
    onlyMonths: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.custom .v-list-item__avatar {
  margin: 0 !important;
}
.custom .v-list-item {
  padding: 0 !important;
}

#addItem {
  margin-bottom: var(--v-heightBottom) !important;
}
</style>
