<template>
  <v-row no-gutters align="center" align-content="center" justify="center">
    <v-col cols="12">
      <swiper :options="swiperOption" ref="mySwiper">
        <template v-for="(alerta, aindex) in alertas">
          <template v-if="validateCondicao(alerta.condicao)">
            <swiper-slide :key="'swiper-slide' + aindex">
              <div class="customcolparent">
                <div
                  :style="{ width: alerta.size ? alerta.size + 'px' : 'auto', 'border':' thin solid black !important'  }"
                  py-2
                  px-2
                  @click="linkAlertaTo(alerta)"
                  :class="
                    (alerta.textcolor ? alerta.textcolor : 'black') +
                      '--text font-weight-medium ' +
                      (alerta.color ? alerta.color : '') +
                      ' text-center body-2 elevation-5 customcol'
                  "
                >
                  <v-icon
                    small
                    :style="{ float: 'left', 'margin-right': '5px' }"
                    :color="alerta.textcolor ? alerta.textcolor : 'black'"
                  >{{ alerta.icone }}</v-icon>
                  {{ alerta.mensagem }}
                </div>
              </div>
            </swiper-slide>
          </template>
        </template>
      </swiper>
    </v-col>
  </v-row>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    alertas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto"
      },
      carouselIndex: 0
    };
  },
  // computed: {
  //   swiper() {
  //     return this.$refs.mySwiper.swiper;
  //   }
  // },
  methods: {
    validateCondicao(condicao) {
      return !condicao || (condicao && this[condicao]());
    },
    canConnect() {
      return !this.$auth.userIsSF();
    },
    linkAlertaTo(alerta) {
      if (alerta.link) this.$util.linkRedirect(alerta.link);
    }
  }
};
</script>

<style>
.customcolparent {
  display: table;
  margin-top: 2px;
  margin-bottom: 2px;
}

.customcol {
  display: table-cell;
  vertical-align: middle;
  border-radius: 5px;
  height: 110px;
  margin-right: 10px;
  padding: 5px 10px;
  max-width: 250px;
  min-width: 50px;
  width: auto;
}
.swiper-slide {
  width: auto !important;
  margin-right: 15px;
}
</style>
