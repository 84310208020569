<template>
  <div id="chart" @click="_initializeChart()">
    <v-skeleton-loader
      class="skeleton-justify"
      type="image"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { protecoesUtil } from './protecoesUtil';
import EuProtegidoService from '@/services/euprotegido/EuProtegidoService';
import {mapMutations} from "vuex";

export default {
  name: 'homeChart',
  data() {
    return {
      chartConfig: null,
      items: [],
      onLoad: false,
    }
  },
  props: {
    totalColor: {
      type: String,
      default: 'FFF',
    },
    chartSize: {
      type: String,
      default: '99%',
    },
    initialChartCircleSize: {
      type: Number,
      default: 118,
    },
    eachChartCircleSize: {
      type: Number,
      default: 14,
    },
  },
  created() {
    this._euProtegidoService = new EuProtegidoService();
  },
  methods: {
    ...mapMutations([
      "loading",
    ]),
    initialize() {
      this.chartConfig = null;
      this.onLoad = true;
      this.loading(true);

      this._euProtegidoService.FindConsolidatedProtectionItensByUserId()
          .then(response => {
            if(response.data) {
              let protectionItens = response.data.protectionItens
              if(protectionItens && protectionItens.length > 0) {
                for(var i=0; i < protectionItens.length; i++){
                  this.items.push({
                    color: protectionItens[i].style,
                    percentual: protectionItens[i].value,
                    descricao: protectionItens[i].key,
                    link: protectionItens[i].key
                  })
                }

              } else {
                this.items = this.getSkeletonChartItens()
              }
            } else {
              this.items = this.getSkeletonChartItens()
            }

          }).catch(error => {
        this.items = this.getSkeletonChartItens()
      }).finally(()=>{
        this.initializeChart();
        this.onLoad = false;
        this.loading(false);
      })
    },
    getSkeletonChartItens(){
      let itens = []
      for(var i=0; i < 3; i++){
        itens.push({ color: '#9E9E9E', percentual: 0.1, descricao: '',  link: '' })
      }
      return itens
    },
    _initializeChart() {
      Highcharts.chart('chart', this.config());
    },
    initializeChart(c) {
      var thiz = this;
      setTimeout(() => {
        if (!c) c = 0;
        var container = document.querySelectorAll('#chartContainer #chart');
        if (c > 8) {
        } else if (container.length > 0) {
          container.forEach(function (elem) {
            thiz._initializeChart();
          });
        } else {
          c++;
          thiz.initializeChart(c);
        }
      }, 250);
    },
    config() {
      if (!this.chartConfig) this.chartConfig = this._config();
      return this.chartConfig;
    },
    _config() {
      var config = this.baseConfig();

      if (this.items.length > 0) {
        var lastSize = null;

        if (this.showChartTotalizer) {
          var total = this.buildTotalItem();

          config.pane.background.push(
            this.buildChartItemBackg(total.item, total.size)
          );

          var totalChartItem = this.buildChartItem(total.item, total.size);
          totalChartItem.selected = true;
          config.series.push(totalChartItem);

          var lastSize = total.size[2];
        }

        for (const [index, item] of this.items.entries()) {
          if (item.percentual) {
            var size = this.buildSize(lastSize);
            lastSize = size[2];

            config.pane.background.push(this.buildChartItemBackg(item, size));
            config.series.push(this.buildChartItem(item, size));
          }
        }
      }
      return config;
    },
    baseConfig() {
      var _ptotal = protecoesUtil.calculateTotal(this.items);
      var ptotal = this.$util.round(_ptotal, 0);

      return {
        chart: {
          type: 'solidgauge',
          height: this.chartSize,
          backgroundColor: 'transparent',
        },
        title: {
          text: '',
        },
        subtitle: {
          text: ptotal > 0 ?
            "<span style='font-size: 35px;color: #EA4965'>" +
            "<span style='font-weight: bold'>" +
            ptotal + "</span>%</span>" +
            "<span v-if='ptotal > 0' style='font-size: 20px;color: #587BBB;font-weight: bold'><br />PROTEGIDO</span>" :
            "<p style='margin: 0; padding-right: 30px; padding-left: 30px; font-size: 16px; color: #757575;font-weight: bold;'>Você ainda não tem cálculo de proteção.</p>",
          useHTML: true,
          floating: true,
          verticalAlign: 'middle',
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false,
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true,
          },
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [],
        },
        series: [],
        tooltip: { enabled: false },
        credits: {
          enabled: false,
        },
      };
    },
    buildTotalItem() {
      var ptotal = protecoesUtil.calculateTotal(this.items);
      var item = {
        descricao: "Protegido",
        percentual: this.$util.round(ptotal, 0),
        color: this.totalColor,
      };
      var size = this.buildSize(null, 8);
      return { item, size };
    },
    buildChartItemBackg(item, size) {
      return {
        outerRadius: size[0],
        innerRadius: size[1],
        backgroundColor: Highcharts.Color(item.color).setOpacity(0.2).get(),
        borderWidth: 0,
      };
    },
    buildSize(lastsize, csize) {
      var size = csize ? csize : this.eachChartCircleSize;
      var ini = lastsize ? lastsize : this.initialChartCircleSize;
      var end = ini - size;
      var arr = [ini + "%", end + 1 + "%", end];
      return arr;
    },
    buildChartItem(item, size) {
      return {
        name: item.descricao,
        data: [
          {
            color: item.color,
            radius: size[0],
            innerRadius: size[1],
            y: item.percentual,
          },
        ],
      };
    },
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style scoped>
  .highcharts-subtitle {
    text-align: center !important;
  }

  .skeleton-justify {
    height: 200px !important;
    width: 200px !important;
    border-radius: 50% !important;
    margin-bottom: 30px !important;
    margin-left: 15% !important;
  }
  
  #chart {
    width: 75%;
    height: auto;
  }
</style>
